import React from "react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FiLogIn } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";
import { FaUserGraduate } from "react-icons/fa";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import ReactIframe from "react-iframe";
import "./App.css";

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClick = () => {
    window.open("https://goo.gl/maps/rRpsfyZuHYCgy6R18", "_blank");
  };
  const [showJob, setShowJob] = useState(false);
  const handleCloseJob = () => setShowJob(false);
  const handleShowJob = () => setShowJob(true);
  return (
    <>
      <header>
        <NavBar />
      </header>
      <main>
        <Container
          fluid={+true}
          className="mx-auto d-flex justify-content-center align-items-center background-1 text-white"
        >
          <Row>
            <Col className="align-items-center" sm={12} md={12} lg={12}>
              <h1 className="text-center display-3 mb-3">
                Köprü Eğitim ile <b>Almanya'ya</b> <br />
                bağlantılar oluşturuyoruz.
              </h1>
              <h1 className="text-center display-6">
                Yarınlarınıza köprüler kuruyoruz.
              </h1>
              <p className="mt-5 text-center">
                <b>
                  Genç yetenekleri Almanya'da birinci sınıf eğitim olanakları
                  ile buluşturuyoruz.
                </b>
              </p>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  style={{ marginRight: "30px" }}
                  className="border border-black bg-black"
                  onClick={handleShowJob}
                >
                  Meslekler
                </Button>
                <Modal
                  show={showJob}
                  onHide={handleCloseJob}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-center">Meslekler</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Köprü Eğitim olarak iş ortağımız ile size sunduğumuz
                      meslek eğitimi ve iş programına ait meslek grupları
                      aşağıdaki gibidir:
                    </p>
                    <ul>
                      <li>
                        <b className="text-primary">Aşçılık</b>
                      </li>
                      <li>
                        <b className="text-primary">Depo Lojistik Uzmanı</b>
                      </li>
                      <li>
                        <b className="text-primary">Hemşirelik Uzmanı</b>
                      </li>
                      <li>
                        <b className="text-primary">Bina Temizliği</b>
                      </li>
                      <li>
                        <b className="text-primary">
                          Restorant ve Etkinlik catering uzmanı
                        </b>
                      </li>
                      <li>
                        <b className="text-primary">
                          Güvenlik ve Güvenlik Uzmanları
                        </b>
                      </li>
                    </ul>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseJob}>
                      Kapat
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Button
                  className="border border-white bg-white text-dark"
                  onClick={handleShow}
                >
                  İletişim
                </Button>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-center">İletişim</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h5>Köprü Eğitim</h5>
                    <ReactIframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.740067468953!2d32.7320500766638!3d39.96956388273301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349c5e458b6cd%3A0x304c44d8ece09713!2s%C4%B0lkyerle%C5%9Fim%2C%201870.%20Cd%20No%3A28%2C%2006370%20Yenimahalle%2FAnkara!5e0!3m2!1str!2str!4v1688759836069!5m2!1str!2str"
                      width="auto"
                      height="auto"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></ReactIframe>
                    <p>
                      {" "}
                      İlk Yerleşim Mahallesi 1870 cadde no 28 Yenimahalle/Ankara
                    </p>
                    <p>+905309021971</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Kapat
                    </Button>
                    <Button variant="primary" onClick={handleClick}>
                      Tamam
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
        </Container>
      </main>

      <section>
        <Container className="mb-5">
          <Row
            className="mx-auto d-flex justify-content-center align-self-center"
            style={{ minHeight: "30vh" }}
          >
            <Col
              sm={12}
              md={12}
              lg={12}
              className="justfy-content-center align-self-center"
            >
              <h2 id="surec" className="text-center p-3">
                Dual Eğitim Size Bu İmkanı Nasıl Sunuyor?
              </h2>
              <p>
                <b>
                  Gastronomi, Depo Lojistiği, Bakım, temizlik, Koruma ve
                  Güvenlik alanlarında Almanya ekonomisinin ihtiyaç duyduğu iş
                  gücünü siz genç ve dinamik arkadaşlarla çözümler yaratıyoruz.
                </b>
              </p>
            </Col>
          </Row>
          <Row className="d-flex">
            <Col sm={4} md={4} lg={4} className="d-flex p-3">
              <Card
                style={{
                  width: "30rem",
                  backgroundColor: "#E8E8E8	",
                }}
                className="border-0 card-hover"
              >
                {/* <Card.Img variant="top" src="./images/registerScene.svg" /> */}
                <Card.Body>
                  <Card.Text className="d-flex align-items-center justify-content-center">
                    <FiLogIn className="icon-register" />
                  </Card.Text>{" "}
                  <Card.Title className="text-center">KAYIT</Card.Title>
                  <Card.Text>
                    Dual Eğitim programına randevu alarak tüm işlemleri
                    başlatabilirsiniz. Geri kalan süreçte birlikte tüm süreç
                    boyunca size yönlendirme sağlayacağız. Almanca dil
                    yetenekleriniz ve nitelikleriniz kontrol edilip
                    sonuçlandıktan sonra gelecek adıma geçmeye hazırız demektir.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} md={4} lg={4} className="d-flex p-3">
              <Card
                style={{
                  width: "30rem",
                  backgroundColor: "#E8E8E8	",
                }}
                className="border-0 card-hover"
              >
                {/* <Card.Img variant="top" src="./images/supportOnline.svg" /> */}
                <Card.Body>
                  <Card.Text className="d-flex align-items-center justify-content-center">
                    <BiSupport className="icon-support" />
                  </Card.Text>
                  <Card.Title className="text-center">DESTEK</Card.Title>
                  <Card.Text>
                    Bünyemizde yer alan, alanında uzman kişiler Almanya’ya
                    gelmenizle birlikte ihtiyaç duyacağınız tüm yardım için
                    sizinle birlikte olacaklar. Konaklamadan okula, okuldan iş
                    hayatına, iş hayatından topluma entegrasyonunuza kadar size
                    destek olmaya hazırılar.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} md={4} lg={4} className="d-flex p-3">
              <Card
                style={{
                  width: "30rem",
                  backgroundColor: "#E8E8E8	",
                }}
                className="border-0 card-hover"
              >
                {/* <Card.Img variant="top" src="./images/finished.svg" /> */}
                <Card.Body>
                  <Card.Text className="d-flex align-items-center justify-content-center">
                    <FaUserGraduate className="icon-finished" />
                  </Card.Text>
                  <Card.Title className="text-center">BİTİRME</Card.Title>
                  <Card.Text>
                    Almanya, küresel düzeyde tanınmış üniversiteleri, kaliteli
                    eğitim sistemi ve geniş iş imkanlarıyla ünlü bir ülkedir.
                    Eğitim süreciniz tamamlanınca, Almanya'da kariyerinizi
                    garanti altına almak için size destek olacağız.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="section-2">
        <Container className="p-3">
          <Row className="d-flex mx-auto align-items-center">
            <Col sm={6} md={6} lg={6}>
              <Image fluid={+true} src="./images/theory.jpg" />
            </Col>
            <Col sm={6} md={6} lg={6} className="p-3">
              <h1 className="display-5">Hem Teorik Hem de Pratik</h1>
              <h2 className="display-6"> Hem Okulda Hem Sahada!</h2>
              <p className="mt-4">
                Teorik eğitimi meslek okulları ile beraber üstlenerek yeni
                geleceğiniz için eksiksiz bir eğitim programı almanı sağlıyoruz.
                Sadece teorik bilgi yetmez! Edindiğiniz bu teorik bilgileri
                pekiştirmek için sizlere iş ortaklarımız aracılığıyla sahada
                uyulama yaparak tecrübe kazanma imkanı veriyoruz. Final olarak
                her iki eğitim aşaması tamamlandığında, uygulama tecrübesi
                kazandığınız iş ortağımız ile kalıcı bir iş anlaşması fırsatı
                yaratıyoruz.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="section-3" className="bg-warning">
        <Container className="d-flex justify-content-center align-items-center">
          <Row>
            <Col>
              <h2 className="p-3 text-center mt-3">
                Eğitim Programımız Hakkında
              </h2>
              <p className="p-3 mx-auto">
                <b>
                  Eğitim programlarımız, mesleklerine başarılı bir başlangıç
                  yapmak isteyenlere gerekli bilgi ve tecrübeye sahip olmalarını
                  sağlamak için benzersiz ve yenilikçi bir yetiştirme
                  programıdır. İş ortaklarımız ve meslek okulları ile beraber
                  işbirliği yapmaktayız. Programa başvuranların en iyi eğitimi
                  alıp iş dünyasının gereksinimlerini tam anlamıyla karşılamaya
                  hazır olması kurum olarak en öncelikli hedefimizdir.
                </b>
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="sss">
        <Container className="p-3 mt-3 mb-3">
          <Row className="d-flex align-items-center ">
            <h2>Sıkça Sorulan Sorular</h2>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Eğitimim süresince nasıl ulaşım sağlarım?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    Bir iş bileti alacaksınız. Bu bilet ile toplu taşımayı
                    ücretsiz olarak kullanabilirsiniz. Otobüsler ve trenler çok
                    sık geçer, bu yüzden ulaşımını kolaylıkla sağlayabilirsiniz.
                    Ancak Alman sürücü belgenizi almak isterseniz sizi
                    destekliyoruz. Birlikte uygun bir sürücü kursu bulur ve
                    kayıt sürecinde size yardımcı oluruz.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Eğitim süresince yan iş yapabilir miyim?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    Haftada 40 saatlik meslek eğitimi sözleşmeniz var. Ya fazla
                    mesai yapma ya da yan iş bulma imkanınız olacak. Ancak bunu
                    bizimle görüşmeniz gerekiyor. Eğitim işletmesi olarak bunu
                    kontrol etmeli ve onaylamalıyız.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Eğitim ne kadar sürer?</Accordion.Header>
                <Accordion.Body>
                  <b>
                    Eğitim genellikle 3 yıl sürer. Bu süre zarfında hem iş
                    ortağınız hem de meslek okulunuzda öğrenir ve çalışırsınız.
                    Eğitim süresi ve tam işleyiş hakkında daha ayrıntılı
                    bilgileri bizden başvurundan sonra alırsınız.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Eğitim için hangi Almanca bilgisine ihtiyacım var?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    JobUniversity’de mesleki öğrenci olmak için Almanca bilgisi
                    gereklidir. Günlük yaşamında ve eğitiminde iyi bir şekilde
                    iletişim kurabilmeni garanti etmek için en az B1 seviye dil
                    bilgisi bekliyoruz. Eğer yeterli Almanca bilgisine sahip
                    değilseniz, sizi öğrenciliğe hazırlanmak için dil kursları
                    sunuyoruz.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Eğitim sırasında ders programı nasıl olacak?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    Genellikle haftanın bir günü meslek okulunda, diğer günlerde
                    iş ortağı işletmede çalışırsınız. Size, tüm önemli tarihleri
                    ve görevleri içeren kişiselleştirilmiş bir ders programı
                    sağlayacağız.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Eğitim sonrası hangi kariyer fırsatlarına sahibim?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    Eğitimini başarıyla tamamladıktan sonra, ya iş ortağınızla
                    kalmak ya da başka bir yerde iş aramak için seçeneğiniz
                    olacak. Eğitiminizin ardından iş aramanızda size destek
                    olmaktan da memnuniyet duyarız.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  Eğitim masraflarını kim karşılar?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    Eğitim masrafları, JobUniversity ve iş ortağınız tarafından
                    karşılanır. Ayrıca meslek ve eğitim yılına bağlı olarak
                    değişen meslek eğitimi ücreti alırsınız.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  Eğitimimi geçemezsem ne olur?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    Eğitiminizi başarıyla tamamlayamazsanız, çeşitli seçenekler
                    mevcuttur. Genellikle yeni bir eğitim ya da alternatif bir
                    iş bulmanıza yardımcı oluruz. Eğitimi tekrarlamak ve
                    yeterlilikleri kazanmak da mümkündür. Her durumda, hangi
                    seçeneklerin olduğunu ve sizi en iyi nasıl
                    destekleyebileceğimizi birlikte kararlaştıracağız.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  Sağlık sigortası, vergiler ve emeklilik sigortası masraflarını
                  kim öder?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    JobUniversity’deki mesleki öğrenim sürecinde resmi olarak
                    sağlık sigortası kapsamındasınız. Bunun masrafları yarı
                    yarıya siz ve JobUniversity tarafından karşılanır. Emeklilik
                    sigortası da yarı yarıya sen ve JobUniversity tarafından
                    ödenir. Vergiler otomatik olarak alacağınız meslek eğitimi
                    ücretinden kesilir ve Maliye’ye aktarılır. Eğer sosyal
                    güvence veya vergi konusunda daha fazla sorunuz varsa, size
                    yardımcı olmaktan memnuniyet duyarız. İnternette harika
                    Brütten Nete hesaplama araçları bulunur, bunlarla kesintiler
                    hakkında bir fikir edinebilirsiniz.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  Almanya'ya gittiğimizde ne yapacağız?
                </Accordion.Header>
                <Accordion.Body>
                  <b>
                    Türkiye'de vize aldıktan sonra Almanya'da havaalanında
                    personelimiz tarafından karşılanacaksınız, ikamet edilecek
                    konutlara yerleşmeniz yapılacaktır, hukuki ve kamu için
                    gerekli belgelerin işlemleri için iş ortağınız olarak
                    yardımcı olacağız. Meslek eğitim sürecinde sizin yanınızda
                    olacağız.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default App;
