import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useState } from "react";
import logo from "../logo512.png";
export default function NavBar() {
  const [activeItem, setActiveItem] = useState("");

  const handleMouseOver = (item) => {
    setActiveItem(item);
  };

  const handleMouseOut = () => {
    setActiveItem("");
  };

  const navLinkStyle = (item) => ({
    borderRadius: activeItem === item ? "8px" : "0",
    marginRight: "10px", // Boşluk için sağ marj eklendi
    backgroundColor: activeItem === item ? "#D3D3D3" : "",
    transition: "all 0.2s", // Stil değişikliklerinin geçiş süresi
  });

  return (
    <>
      <Navbar
        fixed="top"
        expand="md"
        className="bg-body-tertiary flex-grow-1 px-5"
      >
        <Container id="navbar-container">
          <Navbar.Brand className="d-flex align-items-center" href="/">
            <img src={logo} alt="logo" className="img-fluid" width={40} />
            &nbsp;
            <h5>Köprü Eğitim</h5>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            id="navbarScroll"
            className="flex-column align-items-end"
          >
            <Nav navbarScroll style={{ maxHeight: "100px" }}>
              <Nav.Link
                href="/"
                onMouseOver={() => handleMouseOver("anasayfa")}
                onMouseOut={handleMouseOut}
                style={navLinkStyle("anasayfa")}
              >
                Anasayfa
              </Nav.Link>
              <Nav.Link
                href="#surec"
                onMouseOver={() => handleMouseOver("süreç")}
                onMouseOut={handleMouseOut}
                style={navLinkStyle("süreç")}
              >
                Süreç
              </Nav.Link>

              <Nav.Link
                href="#iletisim"
                onMouseOver={() => handleMouseOver("iletisim")}
                onMouseOut={handleMouseOut}
                style={navLinkStyle("iletisim")}
              >
                İletişim
              </Nav.Link>
              <Nav.Link
                href="#sss"
                onMouseOver={() => handleMouseOver("sss")}
                onMouseOut={handleMouseOut}
                style={navLinkStyle("sss")}
              >
                SSS
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
