import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsTelephone } from "react-icons/bs";
import { GrMapLocation } from "react-icons/gr";
import { FiMail } from "react-icons/fi";
import { FiInstagram } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";
import "../components/footer.css";
import { MdOutlinePolicy } from "react-icons/md";
import logo from "../logo512.png";

export default function Footeer() {
  const currentYear = new Date().getFullYear();
  return (
    <Container fluid={+true} className="border-top border-dark" id="iletisim">
      <Row className="border p-5 d-flex align-items-center justify-content-center">
        <Col sm={3} md={3} lg={3}>
          <div className="d-flex mx-3 align-items-center">
            <img src={logo} width={50} alt="logo" className="img-fluid" />{" "}
            &nbsp;
            <h4 className="text-center">Köprü Eğitim</h4>
          </div>
        </Col>
        <Col sm={3} md={3} lg={3}>
          <ul className="f-list">
            <h6>Sosyal Medya</h6>
            <li>
              <a
                inactive={+true}
                href="https://www.facebook.com/profile.php?id=100094930243437"
                target="_blank"
                className="href-facebook"
                rel="noreferrer"
              >
                <FiFacebook className="i-facebook" />
                Facebook
              </a>
            </li>
            <li>
              <a
                inactive={+true}
                href="https://www.instagram.com/kopruegitimtr/"
                target="_blank"
                className="href-instagram"
                rel="noreferrer"
              >
                <FiInstagram className="i-instagram" />
                Instagram
              </a>
            </li>
            <li>
              <a href="/" target="_blank" className="href-threads">
                <img
                  className="i-threads"
                  src="../images/threads-logo.svg"
                  alt="threads-logo"
                />
                Threads
              </a>
            </li>
            <li>
              <a href="/" target="_blank" className="href-twitter">
                <FiTwitter className="i-twitter" />
                Twitter
              </a>
            </li>
          </ul>
          <hr />
        </Col>
        <Col sm={3} md={3} lg={3}>
          <ul className="f-list">
            <h6>Adres</h6>
            <li>
              <GrMapLocation style={{ marginRight: "5px" }} />
              İlk Yerleşim Mahallesi 1870 cadde no 28 Yenimahalle/Ankara
            </li>
            <li>
              <BsTelephone style={{ marginRight: "5px" }} />
              +905309021971
            </li>
            <li>
              <FiMail style={{ marginRight: "5px" }} />
              @egitimkopru.com
            </li>
          </ul>
          <hr />
        </Col>
        <Col sm={3} md={3} lg={3}>
          <ul className="f-list">
            <h6 className="text-center">
              <MdOutlinePolicy />
              Gizlilik Politikası
            </h6>
          </ul>
        </Col>
      </Row>
      <Row fluid={+true} style={{ backgroundColor: "#E8E8E8" }}>
        <Col
          sm={12}
          md={12}
          lg={12}
          className="d-flex align-items-center justify-content-center p-3"
        >
          <p>
            <b>Tüm hakları saklıdır &copy; {currentYear} Köprü Eğitim</b>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
